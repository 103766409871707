import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export type ButtonSizeType = 'sm' | 'md';

export const Wrapper = styled(motion.button)<{
  $gradient?: boolean;
  $fullWidth?: boolean;
  bouncing?: boolean;
  $variant?: 'outlined' | 'gradient' | 'gradient-glow' | 'default';
  $disableAnimation?: boolean;
  $size?: ButtonSizeType;
}>`
  position: relative;
  z-index: 5;
  overflow: hidden;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-decoration: none;

  ${(props) => {
    switch (props.$size) {
      case 'sm': {
        return css`
          font-size: 12px;
          min-height: auto;
          line-height: 1;
          min-width: 132px;
          padding: 15px;
        `;
      }

      case 'md':
      default: {
        return css`
          font-size: 16px;
          height: 56px;
          min-width: 160px;
          padding: 15px 22px;
        `;
      }
    }
  }}

  ${(props) =>
    props.$fullWidth &&
    css`
      width: 100%;
    `}

  > div {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }
  }

  &:before {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: ${(props) => (props.$disableAnimation ? 'none' : 'block')};
    content: '';
    background: linear-gradient(89.1deg, #af26ed 2.8%, #1d97f3 104.57%);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    transform: scaleX(0);
    transform-origin: bottom right;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  &.animation {
    &:before {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    animation: anim 0.4s;

    @keyframes anim {
      0% {
      }
      100% {
      }
    }
  }

  @media (max-width: 768px) {
    &:before {
      display: none !important;
    }
  }

  ${(props) =>
    props.$gradient &&
    css`
      background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);

      &:before {
        display: none;
      }
    `}

  ${(props) => {
    switch (props.$variant) {
      case 'gradient':
        return css`
          background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);

          &:before {
            display: none;
          }
        `;
      case 'gradient-glow':
        return css`
          background: linear-gradient(85.66deg, #af26ed 30.77%, #1d97f3 92.01%);
          overflow: visible;
          &:before {
            display: block !important;
            transform: none;
            border-radius: 20px;
            background: linear-gradient(88deg, #b91bff -0.74%, #27a2ff 100%, #27a2ff 100%);
            filter: blur(17px);
            width: 100%;
            height: 100%;
          }

          &:before:hover {
            transform: none;
          }
        `;
      case 'outlined':
        return css`
          background: transparent;
          border: 1px solid var(--white-transparent-w-20, rgba(255, 255, 255, 0.2));
          color: white;

          &:hover {
            ${!props.$disableAnimation &&
            css`
              border-color: transparent;
            `}
          }
        `;
      default:
        return css``;
    }
  }}
`;
