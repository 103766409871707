"use client";

import { useEffect, useState } from "react";

interface UseInViewOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
  defaultValue?: boolean;
}

function useInView(elementId: string, options?: UseInViewOptions): boolean {
  const [isInView, setIsInView] = useState(options?.defaultValue ?? false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const targetElement = document.getElementById(elementId);
    if (!targetElement) {
      console.warn(`Element with ID "${elementId}" not found.`);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      {
        root: options?.root || null,
        rootMargin: options?.rootMargin || "0px",
        threshold: options?.threshold || 0,
      },
    );

    observer.observe(targetElement);

    return () => observer.unobserve(targetElement);
  }, [elementId, options?.root, options?.rootMargin, options?.threshold]);

  return isInView;
}

export { useInView };
