'use client';

import React from 'react';

import { getOneLink } from './lib';

const context = React.createContext<OneLinkContextType>({
  href: getOneLink(1),
});

const useOneLinkContext = () => React.useContext(context);

function OneLinkContextProvider({ children, initialVariant = 1 }: OneLinkContextProviderProps) {
  const providerValue = React.useMemo<OneLinkContextType>(
    () => ({
      href: getOneLink(initialVariant),
    }),
    [initialVariant],
  );

  return <context.Provider value={providerValue}>{children}</context.Provider>;
}

interface OneLinkContextProviderProps extends React.PropsWithChildren {
  initialVariant: number;
}

interface OneLinkContextType {
  href: string;
}

export { useOneLinkContext, OneLinkContextProvider };
