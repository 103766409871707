import { userAgentFromString } from "next/server";

function getDeviceInfoByUserAgent(ua?: string) {
  const defaultDeviceInfo = {
    isIOS: false,
    isAndroid: false,
    isDesktop: true,
    isMobile: false,
    type: "unknown",
  };

  if (!ua) return defaultDeviceInfo;

  const userAgent = userAgentFromString(ua); // Assuming this function is defined elsewhere

  let deviceInfo = { ...defaultDeviceInfo };

  switch (userAgent.os.name) {
    case "iOS":
      deviceInfo = {
        isIOS: true,
        isAndroid: false,
        isDesktop: false,
        isMobile: true,
        type: "mobile",
      };
      break;
    case "Android":
      deviceInfo = {
        isIOS: false,
        isAndroid: true,
        isDesktop: false,
        isMobile: true,
        type: "mobile",
      };
      break;
    case "Mac OS":
      deviceInfo = {
        isIOS: true,
        isAndroid: false,
        isDesktop: true,
        isMobile: false,
        type: "desktop",
      };
      break;
    case "Windows":
      deviceInfo = {
        isIOS: false,
        isAndroid: false,
        isDesktop: true,
        isMobile: false,
        type: "desktop",
      };
      break;
    default:
      if (userAgent.device.type === "desktop") {
        deviceInfo = {
          isIOS: false,
          isAndroid: false,
          isDesktop: true,
          isMobile: false,
          type: "desktop",
        };
      } else if (userAgent.device.type === "mobile") {
        deviceInfo = {
          isIOS: false,
          isAndroid: false,
          isDesktop: false,
          isMobile: true,
          type: "mobile",
        };
      }
      break;
  }

  return deviceInfo;
}

export { getDeviceInfoByUserAgent };
