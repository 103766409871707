import { getDeviceInfoByUserAgent } from "./model";
import {
  DeviceInfoContext,
  useDeviceInfoContext,
  DeviceInfoContextProvider,
} from "./context";

export type * from "./types";
export {
  getDeviceInfoByUserAgent,
  DeviceInfoContext,
  useDeviceInfoContext,
  DeviceInfoContextProvider,
};
