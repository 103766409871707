'use client';

import { motion } from 'framer-motion';
import Link from 'next/link';
import Image, { ImageProps } from 'next/image';
import React, { ComponentProps } from 'react';
import { ButtonSizeType, Wrapper } from './styled';

export type ButtonProps<As extends string | undefined = 'button'> = React.ComponentProps<typeof Wrapper> & {
  label: React.ReactNode;
  startIcon?: React.ReactElement | ImageProps['src'];
  endIcon?: React.ReactElement | ImageProps['src'];
  gradient?: boolean;
  fullWidth?: boolean;
  disableAnimation?: boolean;
  variant?: 'outlined' | 'gradient' | 'gradient-glow' | 'default';
  as?: As;
  size?: ButtonSizeType;
} & (As extends 'a' ? ComponentProps<typeof Link> : {});

function Button<T extends string | undefined>({
  label,
  gradient = false,
  fullWidth = false,
  disableAnimation = false,
  variant,
  className,
  as,
  size = 'md',
  startIcon,
  endIcon,
  ...props
}: ButtonProps<T>) {
  const animationDisabled = disableAnimation || gradient || variant === 'gradient' || variant === 'gradient-glow';

  return (
    <Wrapper
      $as={as === 'a' ? motion(Link) : as}
      $disableAnimation={animationDisabled}
      $variant={variant}
      $fullWidth={fullWidth}
      onMouseEnter={(e: any) => {
        if (!animationDisabled) {
          e.currentTarget.classList.add('animation');
        }
      }}
      onAnimationEnd={(e: any) => {
        if (!animationDisabled) {
          e.currentTarget.classList.remove('animation');
        }
      }}
      $gradient={gradient}
      className={`btn ${className ?? ''}`}
      $size={size}
      {...props}
    >
      <div>
        {React.isValidElement(startIcon) ? (
          startIcon
        ) : startIcon ? (
          <Image src={startIcon as ImageProps['src']} alt="" width={18} height={18} />
        ) : null}
        {label}
        {React.isValidElement(endIcon) ? (
          endIcon
        ) : endIcon ? (
          <Image src={endIcon as ImageProps['src']} alt="" width={18} height={18} />
        ) : null}
      </div>
    </Wrapper>
  );
}

export { Button };
