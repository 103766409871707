import type { OverridedMixpanel } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';

function loadGtagScript() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };

  const token = process.env.NEXT_PUBLIC_GTAG_TOKEN!;

  // @ts-ignore
  window.gtag('js', new Date());
  window.gtag('config', token);

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${token}`;
  script.async = true;

  document.body.append(script);
}

function loadXScript() {
  eval(`!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
  },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
  twq('config','okyca');`);
}

function loadYMScript() {
  if (typeof window === 'undefined') return;

  (function () {
    window.ym =
      window.ym ||
      function () {
        // @ts-ignore
        (window.ym.a = window.ym.a || []).push(arguments);
      };
    // @ts-ignore
    window.ym.l = 1 * new Date();
    for (var j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === 'https://mc.yandex.ru/metrika/tag.js') {
        return;
      }
    }
    const script = document.createElement('script');
    const firstScript = document.getElementsByTagName('script')[0];
    (script.async = true),
      (script.src = 'https://mc.yandex.ru/metrika/tag.js'),
      firstScript?.parentNode?.insertBefore(script, firstScript);
  })();

  window.ym(Number(process.env.NEXT_PUBLIC_METRICA_TOKEN), 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
    ecommerce: 'dataLayer',
  });

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<div>
            <img
              src="https://mc.yandex.ru/watch/${process.env.NEXT_PUBLIC_METRICA_TOKEN}"
              style={{ position: "absolute", left: "-9999999px" }}
              alt=""
            />
          </div>`;

  document.body.appendChild(noscript);
}

function loadFacebookScript() {
  (function (f, b, e, v, nn, tt, ss) {
    // @ts-ignore
    if (f.fbq) return;
    // @ts-ignore
    let n: any = nn;
    let t: any = tt;
    let s: any = ss;
    n = f.fbq = function () {
      // @ts-ignore
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    // @ts-ignore
    if (!f._fbq) f._fbq = n;
    // @ts-ignore
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    // @ts-ignore
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    // @ts-ignore
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
    ss = s;
    nn = n;
    tt = t;
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

  window.fbq('init', process.env.NEXT_PUBLIC_FACEBOOK_TOKEN!);
}

function loadMixpanel() {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN!, {
    debug: process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development',
    persistence: 'localStorage',
  });
  window.$isMixpanelLoaded = (mixpanel as any).__loaded as boolean;
}

function sendSafeEvent(type: 'fbq' | 'ym' | 'gtag' | 'twq', callback: () => void) {
  if (typeof window === 'undefined' || process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development') {
    return;
  }

  if (typeof window[type] !== 'undefined') {
    callback();
    return;
  }

  let attempt = 0;
  const timerId = setInterval(() => {
    attempt += 1;

    if (attempt > 2) {
      clearInterval(timerId);
      return;
    }

    if (typeof window[type] !== 'undefined') {
      callback();
      clearInterval(timerId);
    } else {
      console.log(`${type} мetric not initialized`);
    }
  }, 500);

  return timerId;
}

function sendSafeYMEvent(name: string, paramets?: object) {
  sendSafeEvent('ym', () => window.ym(Number(process.env.NEXT_PUBLIC_METRICA_TOKEN), 'reachGoal', name, paramets));
}

function sendSafeMixpanelEvent<T extends keyof Omit<OverridedMixpanel, 'people'>>(
  method: T,
  ...params: Parameters<OverridedMixpanel[T]>
) {
  if (typeof window === 'undefined' || process.env.NEXT_PUBLIC_RUNTIME_ENV === 'development') {
    return;
  }

  if (window.$isMixpanelLoaded) {
    // @ts-ignore
    mixpanel[method](...params);
    return;
  }

  let attempt = 0;
  const timerId = setInterval(() => {
    attempt += 1;
    if (attempt > 3) {
      clearInterval(timerId);
      return;
    }

    if (window.$isMixpanelLoaded) {
      // @ts-ignore
      mixpanel[method](...params);
      clearInterval(timerId);
    } else {
      console.log(`mixpanel not initialized`);
    }
  }, 1000);
}

function sendSafeFbqEvent(name: string, parameters?: object) {
  sendSafeEvent('fbq', () => window.fbq('track', name, parameters));
}

function sendSafeGtagEvent(name: string, parameters?: object) {
  sendSafeEvent('gtag', () => window.gtag('event', name, parameters));
}
function sendSafeXEvent(id: string, params?: any) {
  sendSafeEvent('twq', () => window.twq?.('event', id, params));
}

export {
  loadGtagScript,
  loadMixpanel,
  loadFacebookScript,
  loadXScript,
  sendSafeMixpanelEvent,
  sendSafeFbqEvent,
  sendSafeYMEvent,
  loadYMScript,
  sendSafeGtagEvent,
  sendSafeXEvent,
};
