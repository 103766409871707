"use client";

import React from "react";
import { DeviceInfoType } from "./types";

type DeviceInfoContextProps = DeviceInfoType;

const DeviceInfoContext = React.createContext<DeviceInfoContextProps>({
  type: "unknown",
  isMobile: false,
  isAndroid: false,
  isDesktop: false,
  isIOS: false,
});

function DeviceInfoContextProvider<T extends { initValue: DeviceInfoType }>({
  initValue,
  ...props
}: T) {
  return <DeviceInfoContext.Provider value={initValue} {...props} />;
}

function useDeviceInfoContext() {
  return React.useContext(DeviceInfoContext);
}

export { DeviceInfoContextProvider, DeviceInfoContext, useDeviceInfoContext };
