function buildTelegramLinkWithUserDeviceType(
  baseLink: string,
  deviceType = "desktop",
) {
  return deviceType === "mobile"
    ? `https://nicegram.app/deeplink?url=${baseLink}`
    : baseLink;
}

export default buildTelegramLinkWithUserDeviceType;
