import { ONE_LINK_V1, ONE_LINK_V2 } from '@/processes/contexts/one-link/constants';

const getOneLink = (variant: number = 1): string => {
  switch (variant) {
    case 1:
      return ONE_LINK_V1;
    case 2:
      return ONE_LINK_V2;

    default:
      return ONE_LINK_V1;
  }
};

export { getOneLink };
