'use client';

import React, { useCallback, useEffect } from 'react';
import {
  loadFacebookScript,
  loadMixpanel,
  loadYMScript,
  loadGtagScript,
  loadXScript,
  sendSafeXEvent,
  sendSafeGtagEvent,
  sendSafeFbqEvent,
  sendSafeYMEvent,
  sendSafeMixpanelEvent as sendSafeMixpanelBaseEvent,
} from './lib';

type AnalyticsContextProps = {
  sendSafeYMEvent: typeof sendSafeYMEvent;
  sendSafeMixpanelEvent: typeof sendSafeMixpanelBaseEvent;
  sendSafeFbqEvent: typeof sendSafeFbqEvent;
  sendSafeGtagEvent: typeof sendSafeGtagEvent;
  sendSafeXEvent: typeof sendSafeXEvent;
};

const AnalyticsContext = React.createContext<AnalyticsContextProps>({
  sendSafeMixpanelEvent: sendSafeMixpanelBaseEvent,
  sendSafeFbqEvent,
  sendSafeYMEvent,
  sendSafeGtagEvent,
  sendSafeXEvent,
});

function AnalyticsContextProvider<T extends {}>(props: T) {
  const sendSafeMixpanelEvent = useCallback((...args: Parameters<typeof sendSafeMixpanelBaseEvent<any>>) => {
    if (args[0] === 'track') {
      const [method, name, payload, ...aditional] = args;
      return sendSafeMixpanelBaseEvent(
        method,
        name,
        {
          source: 'download',
          ...(typeof payload === 'object' ? payload : {}),
        },
        ...aditional,
      );
    }

    return sendSafeMixpanelBaseEvent(...args);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_RUNTIME_ENV !== 'development') {
      const loadAnalyticScripts = async () => {
        loadFacebookScript();
        loadMixpanel();
        loadYMScript();
        loadGtagScript();
        loadXScript();
        window.removeEventListener('load', loadAnalyticScripts);
      };

      if (document.readyState === 'complete') {
        loadAnalyticScripts();
      } else {
        window.addEventListener('load', loadAnalyticScripts);

        return () => {
          window.removeEventListener('load', loadAnalyticScripts);
        };
      }
    }
  }, []);

  const analyticsSendingFunctions = React.useMemo(() => {
    return {
      sendSafeMixpanelEvent,
      sendSafeFbqEvent,
      sendSafeYMEvent,
      sendSafeGtagEvent,
      sendSafeXEvent,
    };
  }, [sendSafeMixpanelEvent]);

  return <AnalyticsContext.Provider value={analyticsSendingFunctions} {...props} />;
}

function useAnalyticsContext() {
  return React.useContext(AnalyticsContext);
}

export { AnalyticsContextProvider, AnalyticsContext, useAnalyticsContext };
